import { toolBruch, toolFill } from "./Tools";
import "./HeaderProcess.css";
import logo from '../assets/logo.png';
import { useRef } from 'react';

const styles = {
  number: {
    borderRadius: "0.8vw",
    backgroundColor: "#009cc4",
    height: "6vh",
    width: "3vw",
    color: "white",
    fontSize: "1.5vw",
    fontWeight: "1000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    height: "100%",
    color: "#009cc4",
    fontWeight: "700",
    fontSize: "1.8vh",
  },
};

function HeaderProcess(props) {
  const inputRef = useRef(null);

  const options = [
    { value: "fachada", label: "Fachada", img: "fachada.png" },
    { value: "quarto", label: "Quarto", img: "quarto.png" },
    { value: "Sala", label: "Sala", img: "sala.png" },
    { value: "Cozinha", label: "Cozinha", img: "cozinha.png" },
  ];

  const fileInputChange = (evt) => {
    var avisoCanvas = document.getElementById("AvisoCanvas");
    if (avisoCanvas) avisoCanvas.style.display = "none";

    var canvas = document.getElementById("canvasIMG");
    var context = canvas.getContext("2d");

    var canvasRef = document.getElementById("canvas");
    var contextRef = canvasRef.getContext("2d");

    var img = new Image();

    var files = evt.target.files;
    var file = files[0];
    if (file.type.match("image.*")) {
      var reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          img.src = evt.target.result;

          img.onload = () => {
            context.canvas.height = img.height;
            context.canvas.width = img.width;

            contextRef.canvas.height = img.height;
            contextRef.canvas.width = img.width;

            var style = window.getComputedStyle(canvas);

            context.canvas.height = style
              .getPropertyValue("height")
              .replace("px", "");
            context.canvas.width = style
              .getPropertyValue("width")
              .replace("px", "");

            contextRef.canvas.height = style
              .getPropertyValue("height")
              .replace("px", "");
            contextRef.canvas.width = style
              .getPropertyValue("width")
              .replace("px", "");

            context.drawImage(
              img,
              0,
              0,
              context.canvas.width,
              context.canvas.height
            );

            contextRef.drawImage(
              contextRef.canvas,
              0,
              0,
              contextRef.canvas.width,
              contextRef.canvas.height
            );

            canvasRef.style.marginTop = style.getPropertyValue("margin-top");
            canvasRef.style.marginLeft = style.getPropertyValue("margin-left");
            
            contextRef.fillStyle = props.selectedColor?.color;
            contextRef.strokeStyle = props.selectedColor?.color;
          };
        }
      };
    } else {
      alert("imagem inválida");
    }
  };

  const selectStep2Change = (event) => {
    var canvas = document.getElementById("canvasIMG");
    var context = canvas.getContext("2d");

    var img = new Image();
    img.src = require("../assets/" + event.img);

    img.onload = () => {
      context.canvas.height = img.height;
      context.canvas.width = img.width;

      var style = window.getComputedStyle(canvas);
      context.canvas.height = style
        .getPropertyValue("height")
        .replace("px", "");
      context.canvas.width = style.getPropertyValue("width").replace("px", "");

      context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);

      canvas.style.background = `rgb(255, 255, 255, 1)`;
    };
  };

  const setActive = (event) => {
    //TODO: add confirmação para não perder o que fez

    var canvas = document.getElementById("canvasIMG");
    var context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.canvas.height = 600;
    context.canvas.width = 600;
    context.canvas.style.background = "#ffffff00";

    props.setSelectedPickerColor();

    var elems = document.querySelectorAll("input.Step1Button.active");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");

    var tools = document.querySelector(".Tools");
    tools.style.visibility = "hidden";

    var shadowColors = document.getElementById("usedColors");
    if (shadowColors) shadowColors.style.display = "none";

    if (event.target.id === "capturarCor") {
      props.setPickerSelectedTool();
    } else {
      if (event.target.id === "ambienteSelecionado") {
        context.canvas.style.background = context.fillStyle;
        props.setSelectedTool(toolFill);
        props.settoolName("toolFill");
      } else {
        shadowColors.style.display = "flex";
        tools.style.visibility = "visible";
        props.setSelectedTool(toolBruch);
        props.settoolName("toolBruch");

        var toolsSvg = document.querySelectorAll("div.ToolButton.active");
        [].forEach.call(toolsSvg, function (el) {
          el.classList.remove("active");
        });
        document.getElementById("toolPincel").classList.add("active");
      }
    }

    var canvasRef = document.getElementById("canvas");
    var contextRef = canvasRef.getContext("2d");
    contextRef.clearRect(0, 0, canvas.width, canvas.height);

    contextRef.canvas.height = 600;
    contextRef.canvas.width = 600;
    contextRef.canvas.style.background = "#ffffff00";
  };

  return (
    <div style={{ display: 'flex', padding: 2, alignItens: 'center' }}>
        <div style={{ width: '20%', textAlign: 'center', marginLeft: '15px', padding: '5px 0 20px 0' }}>
          <img src={logo} alt="logo" style={{height: '100%'}}></img>
        </div>
        <div style={{ width: '80%' }}>
        <div style={styles.title}>
          <div style={{ width: '100%', textAlign: 'center', color: '#183581', fontWeight: 'bolder', fontSize: '4vh' }}>
            SIMULADOR DE CORES
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <input
              className={"Step1Button active"}
              type="button"
              id="importarAmbiente"
              value="CARREGUE UMA FOTO DE SUA PREFERÊNCIA"
              onClick={(event) => {
                props.setShowSelectOption(false);
                setActive(event);
                inputRef.current.click();
              }}
            ></input>
            <input
              className={"Step1Button"}
              type="button"
              id="ambienteSelecionado"
              value="AMBIENTES PADRÕES"
              onClick={(event) => {
                props.setShowSelectOption(true);
                selectStep2Change(options[0]);
                setActive(event);
              }}
            ></input>
            <input
              className={"Step1Button"}
              type="button"
              id="capturarCor"
              value="CAPTURAR COR DE UMA IMAGEM"
              onClick={(event) => {
                props.setShowSelectOption(false);
                setActive(event);
                inputRef.current.click();
              }}
            ></input>
          </div>
        </div>
      </div>
      <input ref={inputRef} type="file" id="fileImage" style={{display: 'none'}} onChange={fileInputChange}></input>
      {/* <div style={{ ...styles.processStep, ...styles.rightBorder }}> 
        <div style={styles.title}>
          SELECIONE A IMAGEM
          <br />
          {showSelectOption ? (
            <Select
              id="selectAmbientes"
              defaultValue={options[0]}
              options={options}
              onChange={(event) => selectStep2Change(event)}
            />
          ) : (
            <input
              type="file"
              id="fileImage"
              onChange={fileInputChange}
            ></input>
          )}
        </div>
      </div>*/}
    </div>
  );
}

export default HeaderProcess;
